<template>

  <b-card
    v-if="data"
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title>Estados de Periodos</b-card-title>
      <b-dropdown
        variant="link"
        no-caret
        right
        class="chart-dropdown"
        toggle-class="p-0"
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
            class="text-body cursor-pointer"
          />
        </template>
        <b-dropdown-item @click="getYearPeriod('all')">
          Todos
        </b-dropdown-item>
        <template v-for="(year, index) in years">
          <b-dropdown-item 
            :key="index" 
            @click="getYearPeriod(year)"
          >
            {{ year }}
          </b-dropdown-item>
        </template>
      </b-dropdown>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          v-for="(periods, index) in dataCopy"
          :key="periods.periodo"
          cols="3"
        >
          <div
            v-if="index <= 11"
            class="transaction-item mb-1"
            style="border:none;"
          >
            <div
              class="p-1"
              style="border: 1px solid var(--primary); border-radius: 8px"
            >
              <b-media no-body>
                <b-media-aside>
                  <feather-icon
                    size="18"
                    icon="CalendarIcon"
                  />
                </b-media-aside>
                <b-media-body>
                  <h5 class="transaction-title">
                    {{ periods.periodo }}
                  </h5>
                </b-media-body>
                <b-media-aside class="ml-1">
                  <feather-icon
                    v-if="periods.status==='C'"
                    icon="DownloadIcon"
                    size="18"
                    class="text-primary"
                    @click="generateFile(periods.periodo)"
                  />
                  <feather-icon
                    v-else-if="periods.status==='A'"
                    icon="UnlockIcon"
                    size="18"
                    class="text-warning"
                  />
                </b-media-aside>
              </b-media>
            </div>
          </div>
        </b-col>
      </b-row>


    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCol,
  BDropdown,
  BDropdownItem,
  BMedia,
  BMediaAside,
  BMediaBody,
  BRow
} from 'bootstrap-vue'
import environment from "@/environment"


export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BDropdown,
    BDropdownItem,
    BRow, BCol
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      years: [],
      filename: '',
      userData: JSON.parse(localStorage.getItem('userData')),
      periodResults: '',
      selected: 'Todos',
      dataCopy: false,
      loading: false
    }
  },
  mounted() {
    this.dataCopy = this.data
    this.setLastFiveYears()
  },
  methods: {
    getYearPeriod(e){
      if (e === 'all'){
        this.dataCopy = this.data
        this.selected = 'Todos'
      } else {
        this.dataCopy = this.data.filter(periods => periods.anio === e)
        this.selected = e
      }
    },
    async load(period) {
      this.errorMsg = 'Un Momento...'
      this.loading = true
      try {
        const resp = await this.$http.get(`${environment.uri}/profits/distributed/id/${period}`)
        const periodsResp = await this.$http.get(`${environment.uri}/workflows/periods`)

        if (periodsResp && periodsResp.data) {
          this.periods = periodsResp.data.filter(p => p.status !== 'P')
          // this.period = this.periods[0].value
        }

        if (resp && resp.data) {
          this.filename = resp.data
          console.log('filename: ', resp.data)
          this.errorMsg = ''
        } else {
          this.filename = ''
          this.errorMsg = 'No existen una distribución de movimientos asociado a las cuentas contables del periodo VARPERIODO, Para efectuar el cálculo debe presionar el botón "Actualizar Distribución".'
        }
      } catch (err) {
        console.error(err)
      } finally {
        this.loading = false
      }
    },
    async generateFile(period) {
      this.errorMsg = 'Un Momento...'
      this.loading = true
      try {
        const resp = await this.$http.get(`${environment.uri}/profits/distributed/id/${period}`)

        if (resp && resp.data) {
          this.filename = resp.data
          const link = document.createElement('a')
          link.href = this.filename
          link.download = 'Reporte_Rentabilidad_Distribuida-'+period+'.xlsx'

          // Simulate a click on the element <a>
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          console.log('Generated Filename: ', resp.data)
          this.errorMsg = ''
        } else {
          this.filename = ''
          this.errorMsg = 'No existen una distribución de movimientos asociado a las cuentas contables del periodo VARPERIODO, Para efectuar el cálculo debe presionar el botón "Actualizar Distribución".'
        }
      } catch (err) {
        console.error(err)
      } finally {
        this.loading = false
      }
    },
    setLastFiveYears() {
      const currentYear = new Date().getFullYear()

      for (let i = 0; i < 5; i++) {
        this.years.push(String(currentYear - i))
      }
    }
  }
}
</script>
<style>
.transaction-item {
  border-bottom: 1px solid #f2f2f2;
}
</style>
