<template>
  <bar-chart
    :options="options"
    :dataset="dataset"
  />
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle,
} from 'bootstrap-vue'
import {Bar} from 'vue-chartjs'

export default {
  components: {
    BarChart: {
      extends: Bar,
      props: {
        dataset: {
          type: Object,
          required: true
        },
        options: {
          type: Object,
          default: () => ({})
        }
      },
      mounted() {
        this.renderChart(this.dataset, this.options)
      }
    }
  },
  props: {
    dataset: {
      type: undefined,
      default: () => {},
    },
    options: {
      type: undefined,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.graphIcon {
  font-size: 36px;
  stroke-width: 1px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-bottom: .5rem;
}
</style>
