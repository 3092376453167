<template>
  <section
    id="dashboard-ecommerce"
  >
    <b-row>
      <!-- Revenue Report Card -->

      <b-col lg="12">

        <periods
          v-if="DashData"
          :data="DashData.periodos"
        />
        
      </b-col>
    </b-row>

    <b-row>
      <b-col>

        <b-card no-body>
          <b-card-header>
            <b-card-title>Histórico de Rentabilidad Financiera</b-card-title>
          </b-card-header>

          <b-card-body>
            <b-row>
              <b-col sm="4">
                <h4>Gerencia Directiva</h4>
              </b-col>
              <b-col sm="3">
                <h4>Centro de Costo</h4>
              </b-col>
              <b-col sm="3">
                <h4>Periodo</h4>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="4">
                <b-form-group>
                  <b-form-select
                    v-model="chartFilters.admin"
                    text-field="gerencia"
                    value-field="idGerencia"
                    placeholder="Gerencia"
                    :options="admins"
                    @change="filter(chartFilters.admin)"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        default
                      >
                        Todas
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col sm="3">
                <b-form-group>
                  <b-form-select
                    v-model="chartFilters.center"
                    text-field="label"
                    value-field="codigo"
                    :options="centers"
                  >
                    <template #first>
                      <b-form-select-option :value="null">
                        Todos
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col sm="3">
                <b-form-group>
                  <b-form-select
                    v-model="chartFilters.period"
                    text-field="periodo"
                    value-field="periodo"
                    :options="DashData.periodos"
                  >
                    <template #first>
                      <b-form-select-option :value="null">
                        Todos
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col sm="2">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="primary"
                  :disabled="loading"
                  @click="getFinancialChart(chartFilters)"
                >
                  <span v-if="!loading">Buscar</span>
                  <span v-else>Buscando...</span>
                </b-button>
              </b-col>
            </b-row>

            <chartjs-bar-chart
              v-if="chartLoaded === 'Y'"
              :dataset="chartData"
              :options="chartOptions"
            />

            <template v-else-if="chartLoaded === 'N'">
              <div class="graph-container">
                <div
                  v-for="(height, index) in barHeights"
                  :key="index"
                  class="bar"
                >
                  <b-skeleton
                    type="rect"
                    :width="barWidth"
                    :height="height + 'px'"
                    variant="secondary"
                    animation="wave"
                  />
                </div>
              </div>
            </template>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>

        <b-card no-body>
          <b-card-header>
            <b-card-title>Histórico de Rentabilidad Distribuida</b-card-title>
          </b-card-header>

          <b-card-body>
            <b-row>
              <b-col sm="4">
                <h4>Gerencia Directiva</h4>
              </b-col>
              <b-col sm="3">
                <h4>Centro de Costo</h4>
              </b-col>
              <b-col sm="3">
                <h4>Periodo</h4>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="4">
                <b-form-group>
                  <b-form-select
                    v-model="chartDFilters.admin"
                    text-field="gerencia"
                    value-field="idGerencia"
                    placeholder="Gerencia"
                    :options="admins"
                    @change="filter(chartDFilters.admin)"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        default
                      >
                        Todas
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col sm="3">
                <b-form-group>
                  <b-form-select
                    v-model="chartDFilters.center"
                    text-field="label"
                    value-field="codigo"
                    :options="centers"
                  >
                    <template #first>
                      <b-form-select-option :value="null">
                        Todos
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col sm="3">
                <b-form-group>
                  <b-form-select
                    v-model="chartDFilters.period"
                    text-field="periodo"
                    value-field="periodo"
                    :options="DashData.periodos"
                  >
                    <template #first>
                      <b-form-select-option :value="null">
                        Todos
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col sm="2">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="primary"
                  :disabled="loading"
                  @click="getDistributedChart(chartDFilters)"
                >
                  <span v-if="!loading">Buscar</span>
                  <span v-else>Buscando...</span>
                </b-button>
              </b-col>
            </b-row>

            <chartjs-bar-chart
              v-if="chartDLoaded === 'Y'"
              :dataset="chartDataD"
              :options="chartOptions"
            />

            <template v-else-if="chartLoaded === 'N'">
              <div class="graph-container">
                <div
                  v-for="(height, index) in barHeights"
                  :key="index"
                  class="bar"
                >
                  <b-skeleton
                    type="rect"
                    :width="barWidth"
                    :height="height + 'px'"
                    variant="secondary"
                    animation="wave"
                  />
                </div>
              </div>
            </template>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BButton,
  BCard, BCardBody,
  BCardHeader,
  BCardTitle,
  BCol,
  BFormGroup,
  BFormSelect,
  BFormSelectOption,
  BRow,
  BSkeleton
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import environment from '@/environment'
import Periods from './dashboards/Periods.vue'
import chartjsBarChart from "@/views/portal/charts/ChartjsBarChart.vue"

export default {
  components: {
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCard,
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
    BButton,
    BRow,
    BCol,
    Periods,
    chartjsBarChart,
    BSkeleton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      barHeights: [],
      barWidth: '20px',
      user: JSON.parse(localStorage.getItem('userData')),
      DashData: '',
      chartFilters: {
        admin: '',
        center: '',
        period: '',
      },
      chartDFilters: {
        admin: '',
        center: '',
        period: '',
      },
      allCenters: [],
      centers: [],
      admins: [],
      loading: false,
      chartData: null,
      chartLoaded: 'N',
      chartDataD: null,
      chartDLoaded: 'N',
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: function(value, index, values) {
                  if (value >= 1000000) {
                    return '$' + (value / 1000000).toFixed(1) + 'M'
                  } else if (value >= 1000) {
                    return '$' + (value / 1000).toFixed(1) + 'K'
                  } else {
                    return '$' + value
                  }
                }
              },
              scaleLabel: {
                display: false,
              }
            }
          ],
          xAxes: [
            {
              stacked: false
            }
          ]
        },
        legend: {
          display: true
        }
      }
    }
  },
  async created() {
    this.generateBarHeights()
    this.load()
  },
  methods: {
    async getDashboardData() {
      const { data } = await this.$http.get(`${environment.uri}/workflows/dashboard`)
      this.DashData = data
    },
    async getCentersData() {
      const { data } = await this.$http.get(`${environment.uri}/ccenters/all`)
      this.allCenters = data
      this.centers = data
    },
    async getMngData() {
      const { data } = await this.$http.get(`${environment.uri}/mngmts/all`)
      this.admins = data
    },
    async getFinancialChart(chartFilters) {
      try {
        this.chartLoaded = 'N'
        let {data} = await this.$http.post(`${environment.uri}/profits/charts/financial`, {
          PERIODO: chartFilters.period || this.DashData.periodos[0].periodo,
          GERENCIA: chartFilters.admin || '*',
          CENTRO: chartFilters.center || '*'
        })

        data = data.records

        this.chartData = {
          labels: this.getChartLabel(data),
          datasets: [
            {label: 'Activos', data: data.map(d => d.Activos), backgroundColor: 'rgba(255, 99, 132, 0.6)'},
            {label: 'Gastos', data: data.map(d => d.Gastos), backgroundColor: 'rgba(54, 162, 235, 0.6)'},
            {label: 'Ingresos', data: data.map(d => d.Ingresos), backgroundColor: 'rgba(75, 192, 192, 0.6)'},
            {label: 'Pasivos', data: data.map(d => d.Pasivos), backgroundColor: 'rgba(153, 102, 255, 0.6)'},
            {label: 'Reservas', data: data.map(d => d.Reservas), backgroundColor: 'rgba(255, 159, 64, 0.6)'},
            {label: 'Utilidad', data: data.map(d => d.Utilidad), backgroundColor: 'rgba(255, 206, 86, 0.6)'}
          ]
        }
        
        this.chartLoaded = 'Y'
      } catch (error) {
        console.error(error)
        this.chartLoaded = 'E'
      }
    },
    async getDistributedChart(chartFilters) {
      try {
        this.chartDLoaded = 'N'
        let {data} = await this.$http.post(`${environment.uri}/profits/charts/financial`, {
          PERIODO: chartFilters.period || this.DashData.periodos[0].periodo,
          GERENCIA: chartFilters.admin || '*',
          CENTRO: chartFilters.center || '*'
        })

        data = data.records

        this.chartDataD = {
          labels: this.getChartLabel(data),
          datasets: [
            {label: 'Activos', data: data.map(d => d.Activos), backgroundColor: 'rgba(255, 99, 132, 0.6)'},
            {label: 'Gastos', data: data.map(d => d.Gastos), backgroundColor: 'rgba(54, 162, 235, 0.6)'},
            {label: 'Ingresos', data: data.map(d => d.Ingresos), backgroundColor: 'rgba(75, 192, 192, 0.6)'},
            {label: 'Pasivos', data: data.map(d => d.Pasivos), backgroundColor: 'rgba(153, 102, 255, 0.6)'},
            {label: 'Reservas', data: data.map(d => d.Reservas), backgroundColor: 'rgba(255, 159, 64, 0.6)'},
            {label: 'Utilidad', data: data.map(d => d.Utilidad), backgroundColor: 'rgba(255, 206, 86, 0.6)'}
          ]
        }

        this.chartDLoaded = 'Y'
      } catch (error) {
        console.error(error)
        this.chartDLoaded = 'E'
      }
    },
    async load() {
      try {
        this.loading = true
        await this.getDashboardData()
        await this.getCentersData()
        await this.getMngData()

        this.getFinancialChart({})
        this.getDistributedChart({})
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    getChartLabel(array) {
      const period = array.map(item => item.Periodo)
      return [...new Set(period)]
    },
    filter(admin) {
      this.loading = true

      if ((admin || admin === 0)) {
        this.centers = this.allCenters.filter(item => item.idGerencia === admin)
      } else {
        this.centers = this.allCenters
      }

      this.loading = false
    },
    generateBarHeights() {
      for (let i = 0; i < 20; i++) {
        this.barHeights.push(Math.floor(Math.random() * 100) + 50)
      }
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';

.lead .collapse-title {
  font-size: 1.8em;
}

.custom-collapsed {
  font-size: 1.1em;
}
.graph-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 400px;
  padding: 20px;
  background-color: #fff;
}

.bar {
  display: flex;
  align-items: flex-end;
}

.pSkeleton {
  height: 400px;
}
</style>
